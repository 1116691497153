

export const app_bg = {
  bg_blue: "bg-blue-900"
}

export const  emptyImage =  "images/emptyImage.jpg"

export const  profilePictureDefault=""

export const ApiKey = {
  tiny: {
    path: "jdqz7mahdgrp1n38s1ojhs5r76o9fsfahu54klimvo89k367"
  }
}

export const queryCahe = {
  annonces: "annonces",
  annonceDetail: "annonces",
  offreDetail: "annonces",
  offre: "offres",
  profile: {
    candidat: {
      offres: "offreCandidat",
      annonces: "annoncesCandidat",
      profile: "candidatProfile"
    },
    employeur: {
      offres: "employeurOffres",
      annonces: "employeurAnnounces",
      profile: "employeurProfile"
    },
    commun: "commnun"
  },

}


export const IconPack = <svg
  class="w-6 h-6 align-middle"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  stroke="currentColor"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
>
  <path
    d="M22 11.08V12a10 10 0 1 1-5.93-9.14"
  ></path>
  <polyline
    points="22 4 12 14.01 9 11.01"
  ></polyline>
</svg>




export const packsItemsList = [
  {
    pack: "SILVER",
    titre: "Pack STARTER",
    description: "Idéal pour les étudiants souhaitant accéder aux offres d'emploi et démarrer leur carrière.",
    prix: "1000 F",
    avantages: [
      "Accès illimité aux offres d'emploi",
      "Mises à jour en temps réel",
      "Emails et SMS ",
      "Support client dédié",
    ],
  },
  {
    pack: "GOLD",
    titre: "Pack PREMIUM",
    description: "Un pack avancé pour se démarquer auprès des employeurs et accéder à des outils supplémentaires.",
    prix: "2000 F",
    avantages: [
      "Toutes les fonctionnalités du Pack STARTER",
      "Profil en vedette pour les employeurs",
      "Accès au activtés",
      "Mise en avant d'activités et du savoir faire",
      "Chat direct avec les recruteurs",
      "Emails et SMS ",
      "Accès à des formations exclusives",
      "Coaching personnalisé pour la recherche d'emploi",
    ],
  },
  {
    pack: "DIAMOND",
    titre: "Pack VIP",
    description: "Notre offre ultime, incluant des opportunités uniques pour les étudiants ambitieux.",
    prix: "5000 F",
    avantages: [
      "Accès à toutes les fonctionnalités de la plateforme",
      "Participation aux hackathons",
      "Accès exclusif aux startups",
      "Bourse d'etudes",
      "Alertes d'offres d'emploi personnalisées",
      "Coaching professionnel sur mesure",
      "Chat direct avec les recruteurs",
      "Réseau exclusif de recruteurs et d'entreprises",
      "Accès à des bourses d'études à l'étranger",
      "Accès à des formations avancées",
      "Emails et SMS illimités ",
    ],
  },
];




export const packsItemsList2 = [
  {
    pack: "SILVER",
    titre: "Pack STARTER",
    description: "Idéal pour débuter avec des outils essentiels pour trouver des opportunités.",
    prix: "1000 F",
    avantages: [
      "Accès aux offres d'emploi",
      "Accès aux profils des candidats",
      "SMS illimités",
      "Emails illimités",
      "Discussions avec les candidats",
    ],
  },
  {
    pack: "GOLD",
    titre: "Pack PREMIUM",
    description: "Un pack complet pour optimiser vos recrutements et activités.",
    prix: "2000 F",
    avantages: [
      "Accès aux meilleurs profils de candidats",
      "Soumission d'offres personnalisées",
      "Accès aux activités et startups",
      "Campagnes de promotion par email",
      "Formations ",
      "Discussions avec les candidats",
      "SMS illimités",
      "Emails illimités",
    ],
  },
  {
    pack: "DIAMOND",
    titre: "Pack VIP",
    description: "Pour les startups et entreprises souhaitant exploiter tout le potentiel de Jouman.",
    prix: "50000 F",
    avantages: [
      "Accès à toutes les fonctionnalités de la plateforme",
      "Participation aux hackathons",
      "Accès exclusif aux startups",
      "Meilleurs profils d'étudiants",
      "Accès au réseau d'investisseurs",
      "Recommandations de candidats",
      "Formations ",
      "Campagnes de promotion par email",
      "SMS illimités",
      "Emails illimités",
      "Mise en vedette de vos offres d'emploi",
    ],
  },
];



export const statusPACKS = [
  "SILVER", "GOLD", "DIAMOND",]