import React from 'react'
import AboutStart from '../../components/aboutus/AboutStart';
import AboutBarner from '../../components/aboutus/AboutBarner';
import ReactPlayer from 'react-player';

const AboutusPage = () => {
    return (
        <div className="mt-[70px] bg-gradient-to-l from-indigo-700 via-indigo-800 to-black min-h-screen text-white">
            {/* Section de présentation */}
            <header className="bg-indigo-600 text-white py-10">
                <div className="max-w-7xl mx-auto px-6 text-center">
                    <h1 className="text-4xl font-bold">Bienvenue sur Jouman</h1>
                    <p className="mt-4 text-lg text-white">
                        La plateforme qui révolutionne l{"'"}avenir des étudiants et jeunes diplômés.
                    </p>
                </div>
            </header>

            {/* Section fonctionnalités */}
            <section className="py-16 max-w-7xl mx-auto px-6">
                <h2 className="text-3xl font-semibold mb-8 text-center">Fonctionnalités Principales</h2>

                <div className="grid grid-cols-3 gap-10 text-gray-900">
                    <FeatureCard title="Connexion avec des Recruteurs Mondiaux"
                        description="Créez un profil professionnel visible par des recruteurs de renommée." icon="🌎" />
                    <FeatureCard title="Formations et Ateliers Pratiques"
                        description="Des cours spécialisés pour répondre aux besoins du marché actuel." icon="🎓" />
                    <FeatureCard title="Bourses d'Études Internationales"
                        description="Accédez facilement à des bourses pour étudier à l'étranger." icon="📚" />
                    <FeatureCard title="Réseautage et Partage d'Expériences"
                        description="Rejoignez une communauté d’étudiants et jeunes diplômés." icon="🤝" />
                    <FeatureCard title="Mise en Avant des Talents"
                        description="Affichez vos compétences et projets pour attirer les recruteurs." icon="🌟" />

                    <FeatureCard title="Decouverte du monde de l'entreprise"
                        description="Affichez vos compétences et projets pour attirer les recruteurs." icon="🌟" />
                </div>
            </section>

            {/* Section Vidéo de Présentation */}
            <section className="bg-gradient-to-l from-indigo-700 via-indigo-800 to-black py-16">
                <div className="max-w-5xl mx-auto px-6 text-center">
                    <h2 className="text-3xl font-semibold mb-6 text-white">Présentation</h2>
                    <p className="mb-6 text-white">Découvrez les fonctionnalités de Jouman en vidéo.</p>
                    <div className="relative pt-[56.25%] overflow-hidden">
                        <ReactPlayer className="absolute top-0 left-0 w-full h-full"
                            url={"jouman_presentation.mp4"}
                            controls
                            width={"100%"}
                            height={"450px"}
                        />
                        
                    </div>
                </div>
            </section>

            {/* Section Captures d'Écran */}
            <section className="py-16 max-w-7xl mx-auto px-6 ">
                <h2 className="text-3xl font-semibold mb-8 text-center">Solutions Apportées par Jouman</h2>
                <div className="grid grid-cols-3 gap-6 text-gray-900">
                    <ScreenshotCard title="Plateforme d'Accueil et Espace Personnel" src="img/Plateforme_d_Accueil_et_Espace_Personnel.jpg" />
                    <ScreenshotCard title="Catalogue de Formations Professionnelles" src="img/online_education.jpg" />
                    <ScreenshotCard title="Recherche d'Emploi et de Stages" src="img/job search.jpg" />
                    <ScreenshotCard title="Opportunités de Bourses d'Études à l'Étranger" src="img/scholarship_opportunity.jpg" />
                    <ScreenshotCard title="Mise en Relation Directe avec les Recruteurs" src="img/networking_with_recruiter.jpg" />
                    <ScreenshotCard title="Visibilité et Réseau pour Talents Jeunes" src="img/talent_networking.jpg" />
                </div>
            </section>

            {/* Section Pourquoi Rejoindre Jouman */}
            <section className="bg-indigo-100 py-16">
                <div className="max-w-5xl mx-auto px-6">
                    <h2 className="text-3xl font-semibold mb-8 text-center text-indigo-800">Pourquoi Rejoindre Jouman ?</h2>
                    <ul className="space-y-4">
                        <li className="flex items-center space-x-4">
                            <span className="text-indigo-600 text-3xl">✔️</span>
                            <p>Un accès direct aux opportunités mondiales.</p>
                        </li>
                        <li className="flex items-center space-x-4">
                            <span className="text-indigo-600 text-3xl">✔️</span>
                            <p>Des formations et certifications pour booster vos compétences.</p>
                        </li>
                        <li className="flex items-center space-x-4">
                            <span className="text-indigo-600 text-3xl">✔️</span>
                            <p>Une expérience utilisateur simple et efficace.</p>
                        </li>
                        <li className="flex items-center space-x-4">
                            <span className="text-indigo-600 text-3xl">✔️</span>
                            <p>Une communauté engagée et solidaire.</p>
                        </li>
                    </ul>
                </div>
            </section>

            {/* Section Téléchargement */}
            <section className="py-16 text-center">
                <h2 className="text-3xl font-semibold mb-8">Téléchargez l'Application Jouman Aujourd'hui !</h2>
                <p className="mb-4 text-white">Disponible sur iOS et Android</p>
                <div className="flex justify-center space-x-4">
                    <button className="bg-indigo-600 text-white py-2 px-6 rounded-lg hover:bg-indigo-700">App Store</button>
                    <button className="bg-indigo-600 text-white py-2 px-6 rounded-lg hover:bg-indigo-700">Google Play</button>
                </div>
            </section>
        </div>
    );
};

// Composant FeatureCard
const FeatureCard = ({ title, description, icon }) => (
    <div className="bg-white p-6 shadow-lg rounded-lg">
        <div className="text-3xl">{icon}</div>
        <h3 className="text-xl font-semibold mt-4">{title}</h3>
        <p className="mt-2 text-gray-900">{description}</p>
    </div>
);

// Composant ScreenshotCard
const ScreenshotCard = ({ title, src }) => (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <img src={src} alt={title} className="w-full h-48 object-cover" />
        <div className="p-4">
            <h4 className="text-lg font-semibold text-gray-900">{title}</h4>
        </div>
    </div>
);

export default AboutusPage;