import React, { useState } from 'react';
import { competences, level_School } from '../../utlis/options/candidatOption';
import Select from 'react-select';
import { optionPays } from '../../utlis/options/optionDivers';
import { routing } from '../../utlis/routing';
import { toast } from 'react-toastify';
import { CandidatConnexion } from '../../action/api/candidat/CandidatAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EntrepriseConnexion } from '../../action/api/employeur/EmployeurAction';
import LoadinButton from '../../components/loading/LoadinButton';



const SignInRecuteurPage = () => {


    const navigate = useNavigate();

    const [candidats, setCandidats] = useState([
        {
            _id: "1",
            name: "Bly Bi Gohi Aymar",
            active: true,
            coverPicture: "https://img.freepik.com/photos-premium/homme-etudiant-afro-americain-fond-jaune-isole-lunettes-heureux_1368-222691.jpg?w=900",
            profession: "Développeur full stack, Animateur, Modélisateur 3D",
            description: "Je suis vraiment content de cette plateforme. J'ai eu une meilleure influence grâce à cela dans ma filière."
        },
        {
            _id: "2",
            name: "Alicia Touré",
            active: true,
            coverPicture: "https://images.pexels.com/photos/1858175/pexels-photo-1858175.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
            profession: "Étudiante en Informatique et Marketing",
            description: "Je suis vraiment contente de cette plateforme. J'ai eu une meilleure influence grâce à cela dans ma filière."
        },
        {
            _id: "3",
            name: "Cédric",
            active: true,
            coverPicture: "https://img.freepik.com/photos-gratuite/etudiant-positif-peau-foncee-porte-dossiers-livre-pointe-expression-joyeuse-cote-sourire-pleines-dents_273609-23704.jpg?w=900&t=st=1693235710~exp=1693236310~hmac=2afd47b244941ca069e099779258dc77df9a96f3aedddf1511fdfd8d8e8c5479",
            profession: "Étudiant en Informatique et Marketing",
            description: "Je suis vraiment content de cette plateforme. J'ai eu une meilleure influence grâce à cela dans ma filière."
        }
    ]);




    const [email, setemail] = useState();
    const [password, setpassword] = useState();
    const [isPassword, setisPassword] = useState(false)



    const [selectedOptions, setSelectedOptions] = useState([]);

    //
    const showErrorToast = (message) => {
        toast.info(message, {
            position: "top-right",
            autoClose: 3000, // Durée d'affichage du toast en millisecondes
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };
    //
    const handleSelectChange = selectedOptions => {
        setSelectedOptions(selectedOptions);
    };

    // state de redux
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.reducer.loading);
    const error = useSelector((state) => state.reducer.error);

    //valdation de formulaire
    // valider inscription 
    const hanldeSubmitCandidat = (event) => {
        event.preventDefault();

        const requiredFields = [
            // boc 1
            "email", "password"
        ];

        // Vérifiez chaque champ requis.
        for (const field of requiredFields) {
            if (!eval(field)) {
                showErrorToast(
                    //`${field.replace("_", " ")} requis !`
                    `Champs avec * sont obligatoires`
                );
                return; // Arrêtez le traitement si un champ est vide.
            }
        }


        dispatch(EntrepriseConnexion(email, password, navigate, toast));

    }


    return (
        <main className="crp1m">


            <div className="cjiiw cdg1p coz82">

                <div className="cyzui">

                    <div className="ckjzp c9dke c6to5 cj2th cscbh cyzui coz82 crp1m cx27s">


                        <header className="c62g5 cmdkn crp1m justify-center">
                            <div className="c7kkg czlxp cf6y5 crp1m c7htb">
                                <a className="cfkm3 chkpc" href="/" aria-label="Cruip">
                                    <img src="assets/images/logo-dark.png" class="h-[50px] w-[50px] rounded-full" />
                                </a>
                            </div>
                        </header>

                        <div className="cmdkn cggc7">

                            <div className="cjplb">
                                <h1 className="cukoz c4q7l ca00q c7csb">Connexion Employeur </h1>
                                <div className="clvg0">Veilleur renseigner vos information pour vous connectez</div>
                            </div>
                            <form onSubmit={hanldeSubmitCandidat}>

                                <div className="cz2ao">
                                    <div className="chva6">
                                        <div>
                                            <label className="ckncn c9csv cfkm3 ckcgr" for="email">Email de votre entreprise <span className="cvmpf">*</span></label>
                                            <input className="cvac0 coz82" value={email} onChange={(e) => { setemail(e.target.value) }} type="email" required={true} />
                                        </div>
                                    </div>
                                    <div className="chva6">
                                        <div>
                                            <label className="ckncn c9csv cfkm3 ckcgr" for="email">Mot de passe <span className="cvmpf">*</span></label>
                                            <input className="cvac0 coz82" value={password} onChange={(e) => { setpassword(e.target.value) }} type={isPassword ? "text" : "password"} required={true} />
                                        </div>
                                    </div>

                                    <div className="chva6 mt-5">
                                        <div class="flex justify-start space-x-3 align-center">
                                            <input className="cvac0 coz82 h-[20px] w-[20px]" checked={isPassword} onChange={(e) => { setisPassword(e.target.checked) }} type="checkbox" />
                                            <label className="ckncn c9csv cfkm3 ckcgr" for="email">Afficher mot de passe </label>
                                        </div>
                                    </div>

                                    <div className="chva6 mt-5">
                                        <div class="flex justify-start space-x-3 align-center">
                                            <label className="ckncn c9csv cfkm3 ckcgr text-indigo-800 text-center" for="email"><a href={`/${routing.forgetpasswordhash}`} class="">J{"'"}ai oublié mon mot passe ?</a> </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="cq38v">
                                    {
                                        loading ?
                                            <LoadinButton text={"Connexion en cours ..."} />
                                            :
                                            <button type="submit" className="bg-blue-600 cd99b croe6 cday3 c8dh7 coz82 chkpc ct2sf">
                                                Se Connecter <span className="cls93 cv1su cwp6w c8h2n c04ox c94my cg4yh"></span>
                                            </button>
                                    }
                                </div>
                            </form>

                            <div className="czlxp cp545 crp1m">
                                <div className="cuiwd ch0ai conht cyy4k" aria-hidden="true"></div>
                                <div className="clvg0 c9csv cqaaz">Or</div>
                                <div className="cuiwd ch0ai ca1o4 cyy4k" aria-hidden="true"></div>
                            </div>

                            <a href={`/${routing.inscription_recruteur}`}>
                                <button type="submit" className="bg-gray-100 text-gray-900 cd99b croe6 cday3 c8dh7 coz82 chkpc ct2sf">
                                    S{"'"}inscrire <span className="cls93 cv1su cwp6w c8h2n c04ox c94my cg4yh"></span>
                                </button>
                            </a>

                        </div>

                    </div>

                </div>

            </div>




            <div className="c78an cdg1p cd3zq cptbr cn73e ca2z8 cv3zt cb3sj" aria-hidden="true">


                <div className="cp8r2 c0wb5 ch30j c5u32 clp4d cdf7d cxio3" aria-hidden="true"></div>


                <div className="cp8r2 c6bzk c5u32 cn73e ca2z8 cxio3" aria-hidden="true">
                    <img src="images/auth-illustration.svg" className="cj4he" width="1440" height="900" alt="Page Illustration" />
                </div>


                <div className="chakn c5u32 cj2th cdf7d crp1m">
                    <div className="c1dhf c6tf9">
                        <div className="cq8kw cscbh coz82">
                            <div className="cj473 chkpc">




                                {
                                    candidats.map((item) => {
                                        return (
                                            <div key={item._id} className="c7tiu ccnwv c04ox c94my ckgol caxg1 cd9g6 ciwnj cmlda">
                                                <div className="czlxp calvf crp1m">
                                                    <div className="cpsdf cyzlo">
                                                        <img className="c59v3" src={item.coverPicture} width="88" height="88" alt="Testimonial 02" />
                                                        <svg className="curhz c5u32 cn73e cb3sj" width="26" height="17" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M0 16.026h8.092l6.888-16h-4.592L0 16.026Zm11.02 0h8.092L26 .026h-4.65l-10.33 16Z"></path>
                                                        </svg>
                                                    </div>
                                                    <figure>
                                                        <blockquote className="cqnva cy3kw cu9ao">
                                                            <p>{item.description}</p>
                                                        </blockquote>
                                                        <figcaption className="ckncn c9csv">{item.name}  , {item.profession} <a className="c91mf c29l8" href="#"> emplois</a></figcaption>
                                                    </figure>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </div>

            </div>



        </main>
    )
}

export default SignInRecuteurPage;
