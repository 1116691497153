import React from 'react'
import BarnerFormationList from '../../components/coachingAndFormation/formation/BarnerFormationList'
import FormationListItem from '../../components/coachingAndFormation/formation/FormationListItem'

const FormationPage = () => {
    return (
        <div className="main-content">
            <div className="page-content pt-28  bg-gradient-to-l from-indigo-700 via-indigo-800 to-black">



            <BarnerFormationList/>

            <FormationListItem/>






            </div>
        </div>
    )
}

export default FormationPage
