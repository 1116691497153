import axios from "axios";
import { baseurl } from "../../../utlis/url/baseurl";
import { routing } from "../../../utlis/routing";
import { dureeDeVie, localvalue, localvalueStorage, typePersonConnected } from "../../../utlis/storage/localvalue";
import { getAndCheckLocalStorage, handleClearLocalStorage, setWithExpiration } from "../../../utlis/storage/localvalueFunction";
import { REQUEST_FAILURE, REQUEST_SUCCESS, SEND_REQUEST } from "../../../app/actions";
import { useState } from "react";
import { useEffect } from "react";
import { getDataFromFile, saveDataToFile } from "../../storage/DataLocal";
import { toast } from "react-toastify";


var idCandidat = getAndCheckLocalStorage(localvalue.candidatID);




// Créer un Candidat
// Fonction pour ajouter des administrateurs à l'application
export const CandidatSignUp = (

    username,
    firstname,
    lastname,
    description,
    dateNaissance,
    email,
    title_post,
    salaire,
    telephone,
    addresse,
    pays,
    level_school,
    site_web,
    years_experience,
    selectedOptions,
    selectedOptionsLangues,
    facebook_url,
    linkedin_url,
    twitter_url, instagram_url,codePostal
    , toast) => {
    return async (dispatch) => {
        dispatch({ type: SEND_REQUEST });
        await axios
            .post(`${baseurl.url}/api/v1/candidat/`,
                {
                    "username": username,
                    "firstname": firstname,
                    "lastname": lastname,
                    "description": description,
                    "dateNaissance": dateNaissance,
                    "email": email,
                    "title_post": title_post,
                    "salaire": salaire,
                    "telephone": telephone,
                    "codePostal":codePostal,
                    "adresse": addresse,
                    "pays": pays,
                    "level_school": level_school,
                    "site_web": site_web,
                    "years_experience": years_experience,
                    "competences": selectedOptions,
                    "langues": selectedOptionsLangues,
                    "facebook_url": facebook_url,
                    "linkedin_url": linkedin_url,
                    "twitter_url": twitter_url,
                    "instagram_url": instagram_url
                }

                , {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                })
            .then((response) => {
                dispatch({ type: REQUEST_SUCCESS, payload: response.data });
                if (response.status == 200) {
                    toast.success(" Votre mot de passe vous été envoyer par email")
                    setTimeout(() => {
                        window.location.href = `/${routing.connexion}`;
                    }, 2500);
                }

            })
            .catch((error) => {
                dispatch({ type: REQUEST_FAILURE, payload: error.message });
                toast.error("Inscription échouée !")
            });
    };
}

// Candidat Generale Edit
export const CandidatEditGenerale = (
    id,
    username,
    firstname,
    lastname,
    dateNaissance,
    email,
    title_post,
    telephone,
    addresse,
    pays, toast) => {
    return async (dispatch) => {
        dispatch({ type: SEND_REQUEST });
        await axios
            .put(`${baseurl.url}/api/v1/candidat/edit/${id}`,
                {
                    "username": username,
                    "firstname": firstname,
                    "lastname": lastname,
                    "dateNaissance": dateNaissance,
                    "email": email,
                    "title_post": title_post,
                    "telephone": telephone,
                    "adresse": addresse,
                    "pays": pays,
                }, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: REQUEST_SUCCESS, payload: response.data });
                toast.success(" Profile mis à jour")
                setTimeout(() => {
                    window.location.reload();
                }, 2500);

            })
            .catch((error) => {
                dispatch({ type: REQUEST_FAILURE, payload: error.message });
                toast.error("Mis à jour impossible !")
            });
    };
}






// Edit Competence
export const CandidatEditCompetence = (
    id,
    salaire,
    level_school,
    years_experience,
    selectedOptions,
    selectedOptionsLangues, description
    , toast) => {
    return async (dispatch) => {
        dispatch({ type: SEND_REQUEST });
        await axios
            .put(`${baseurl.url}/api/v1/candidat/edit/${id}`,
                {

                    "salaire": salaire,
                    "level_school": level_school,
                    "years_experience": years_experience,
                    "competences": selectedOptions,
                    "langues": selectedOptionsLangues,
                    "description": description
                }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: REQUEST_SUCCESS, payload: response.data });
                toast.success(" Mis à jour réussi")
                setTimeout(() => {
                    window.location.reload();
                }, 2500);
            })
            .catch((error) => {
                dispatch({ type: REQUEST_FAILURE, payload: error.message });
                toast.error("Misa a jour  impossible !")
            });
    };
}




// Edit Competence
export const CandidatEditSocial = (
    id,
    facebook_url,
    linkedin_url,
    twitter_url,
    instagram_url,
    site_web
    , toast) => {
    return async (dispatch) => {
        dispatch({ type: SEND_REQUEST });
        await axios
            .put(`${baseurl.url}/api/v1/candidat/edit/${id}`,
                {

                    "facebook_url": facebook_url,
                    "linkedin_url": linkedin_url,
                    "twitter_url": twitter_url,
                    "instagram_url": instagram_url,
                    "site_web": site_web,
                }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: REQUEST_SUCCESS, payload: response.data });
                toast.success(" Mis à jour réussi")
                setTimeout(() => {
                    window.location.reload();
                }, 2500);
            })
            .catch((error) => {
                dispatch({ type: REQUEST_FAILURE, payload: error.message });
                toast.error("Misa à jour impossible !")
            });
    };
}




export const CandidatEditPhoto = (
    id, coverPicture, toast) => {
    return async (dispatch) => {
        dispatch({ type: SEND_REQUEST });
        await axios
            .put(`${baseurl.url}/api/v1/candidat/edit/${id}`,
                {
                    "coverPicture": coverPicture
                }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: REQUEST_SUCCESS, payload: response.data });
                toast.success(" Photo modifer")
            })
            .catch((error) => {
                dispatch({ type: REQUEST_FAILURE, payload: error.message });
                toast.error("Photo non modifié !")
            });
    };
}




// candidat edit password
export const CandidatEditPassword = (id, password, toast) => {
    return async (dispatch) => {
        dispatch({ type: SEND_REQUEST });
        await axios
            .put(`${baseurl.url}/api/v1/candidat/password/edit/${id}`,
                {
                    "password": password
                }, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: REQUEST_SUCCESS, payload: response.data });
                toast.success("Mise a jour mot passe effectuer");
                setTimeout(() => {
                    window.location.reload();
                }, 2500);
            })
            .catch((error) => {
                dispatch({ type: REQUEST_FAILURE, payload: error.message });
                toast.error("Mot passe non modifier");
            });
    };
}




// candidatEdit pass




export const CandidatEditProfile = (id, data) => {
    return async (dispatch) => {
        dispatch({ type: SEND_REQUEST });
        await axios
            .put(`${baseurl.url}/api/v1/candidat/edit/${id}`, data, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: REQUEST_SUCCESS, payload: response.data });
                window.location.reload();
            })
            .catch((error) => {
                dispatch({ type: REQUEST_FAILURE, payload: error.message });
            });
    };
}



export const CandidatEditCv = (id, data) => {
    return async (dispatch) => {
        dispatch({ type: SEND_REQUEST });
        await axios
            .put(`${baseurl.url}/api/v1/candidat/edit/${id}`, data, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: REQUEST_SUCCESS, payload: response.data });
                window.location.reload();
            })
            .catch((error) => {
                dispatch({ type: REQUEST_FAILURE, payload: error.message });
            });
    };
}



export const CandidatPostuleOneOffre = (idcandidat, idOffre, toast) => {
    return async (dispatch) => {
        dispatch({ type: SEND_REQUEST });
        await axios
            .post(`${baseurl.url}/api/v1/candidat/get_candidat/${idcandidat}/postuler/${idOffre}/offres`, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: REQUEST_SUCCESS, payload: response.data });
                toast.success("Votre candidature à été posté avec succès 😉");
                // setTimeout(() => {
                //     window.location.reload();
                // }, 2500);
            })
            .catch((error) => {
                dispatch({ type: REQUEST_FAILURE, payload: error.message });
                toast.error("Imoossible de poster votre candidature  😭!")
            });
    };
}





// Authenfication du candidate

export const CandidatConnexion = (email, password, redirect, toast) => {
    return async (dispatch) => {
        dispatch({ type: SEND_REQUEST });
        await axios
            .post(`${baseurl.url}/api/v1/auth/candidat/login`, {
                "email": email,
                "password": password
            }, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: REQUEST_SUCCESS, payload: response.data });
                // efface tous les données en localStorage de l'application
                handleClearLocalStorage();

                // assignation des dnnées du localStorage de du candycat
                setWithExpiration(
                    localvalue.candidatID,
                    response.data.data._id,
                    dureeDeVie
                );
                setWithExpiration(
                    localvalue.TYPEACCESS,
                    typePersonConnected[1],
                    dureeDeVie
                );
                setWithExpiration(
                    localvalue.token,
                    response.data.token,
                    dureeDeVie
                );

                // redirect(`/${routing.candidatDashboard.path}`);
                toast.success("Connexion Réussi ! ");
                setTimeout(() => {
                    redirect(`/${routing.candidat_details}`);
                }, 4000);

            })
            .catch((error) => {
                dispatch({ type: REQUEST_FAILURE, payload: error.message });
                toast.error("Impossible de se connecter ! ")

            });
    };
}


export const CandidatDeconnexion = async (id) => {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseurl.url}/api/v1/auth/candidat/disconnect_candidat/${id}`,
        headers: {
            'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
        }
    };
    await axios(config)
        .then((response) => {
            // localStorage.removeItem(localvalue.candidat.tokenCandidat, response.data.data.token);
            // localStorage.removeItem(localvalue.candidat.idCandidat, response.data.data._id);
            // localStorage.removeItem(localvalue.candidat.emailCandidat, response.data.data.email);
            // localStorage.removeItem(localvalue.typeAdmin, response.data.data.type);
            // localStorage.removeItem(localvalue.candidat.coverPictureCandidat, response.data.data.coverPicture);

            // localStorage.removeItem(localvalue.emloyeur.tokenEmployeur, response.data.data.token);
            // localStorage.removeItem(localvalue.emloyeur.idEmployeur, response.data.data._id);
            // localStorage.removeItem(localvalue.emloyeur.emailEmployeur, response.data.data.email);
            handleClearLocalStorage();
            // redirect(`${routing.historique.path}`);
            window.location.reload();
        })
        .catch((error) => {
            // console.log(error);
        });

}



// recupérer  tous les candidats

export const CandidatGetAll = async (setState, setState2) => {
    const getData = getDataFromFile(localvalueStorage.CANDIDATS)
    setState(getData)
    setState2(getData)
    await axios.get(`${baseurl.url}/api/v1/candidat/get_candidats`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
        }
    })
        .then((response) => {
            setState(response.data.data);
            setState2(response.data.data);
            saveDataToFile(response.data.data, localvalueStorage.CANDIDATS)
        })
        .catch((error) => {
            // console.log(error);
        });

}




// Recupérer mes information du cv
export const CandidatGetCvById = async (id, setState) => {

    await axios.get(`${baseurl.url}/api/v1/candidat/get_candidat/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
        }
    })
        .then((response) => {
            setState(response.data.data.cv);
        })
        .catch((error) => {
            // console.log(error);
        });

}






// Recupérer tout les candiats qui ont postulés à l'offre
export const CandidatGetCandidatpostulesByOffre = async (id, setState) => {
    await axios.get(`${baseurl.url}/api/v1/offre/get_offre/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
        }
    })
        .then((response) => { setState(response.data.data.candidatPostulees); })
        .catch((error) => {
            // console.log(error);
        });
}


export const CandidatGetCandidatpostulesByAnnonce = async (id, setState) => {
    await axios.get(`${baseurl.url}/api/v1/annonce/get_annonce/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
        }
    })
        .then((response) => { setState(response.data.data.candidats); })
        .catch(() => {
            // console.log(error); 
        });
}



export const CandidatGetById = async (id, setState) => {

    await axios.get(`${baseurl.url}/api/v1/candidat/get_candidat/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
        }
    })
        .then((response) => {
            setState(response.data.data);
        })
        .catch(() => {
            // console.log(error);
        });

}



// Offres postuler par le candidat
export const CandidatGetAllOffrePostulees = async (candidatId, setState, setState2) => {

    await axios.get(`${baseurl.url}/api/v1/candidat/get_candidat/${candidatId}/offres`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
        }
    })
        .then((response) => {
            setState(response.data.data)
            setState2(response.data.data)
        })
        .catch((error) => {
            // console.log(error);
        });

}


// recuprer les annonces d'un candidat 
export const CandidatGetAllAnnoncesPostulees = async (candidatId, setState, setState2) => {

    await axios.get(`${baseurl.url}/api/v1/candidat/get_candidat/${candidatId}/annonces`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
        }
    })
        .then((response) => {
            setState(response.data.data)
            setState2(response.data.data)
        })
        .catch((error) => {
            // console.log(error);
        })


}



//state pour le candidat

export default function useFetchCandidat(idCandidat) {
    const [candidat, setCandidat] = useState({
        coverPicture: "",
        username: "",
        email: "",
        telephone: "",
        firstname: "",
        lastname: "",
        addresse: "",
        competences: [],
        langues: [],
        pays: "",
        adresse: "",
        salaire: "",
        title_post: "",
        description: "",
        dateNaissance: "",
        level_school: "",
        site_web: "",
        years_experience: "",
        facebook_url: "",
        linkedin_url: "",
        twitter_url: "",
        instagram_url: "",
        bookmarks: [],
        transactions: [],
        offres: [
            {
                _id: ""
            }
        ],
        account: {
            solde: 0,
            pack: "",
            count_sms: 0
        }
    });
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);








    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            // console.log(idCandidat);
            await axios.get(`${baseurl.url}/api/v1/candidat/get_candidat/${idCandidat}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            }).then((response) => {
                setCandidat(response.data.data);
                saveDataToFile(response.data.data, localvalueStorage.CANDIDAT)
                setError(null);
                // console.log(response.data.data)
            })
                .catch((error) => {
                    // console.log(error);
                    setError(error);
                });

            setIsLoading(false);
        }

        if (idCandidat) {
            fetchData();
        }
    }, [idCandidat]);

    return { isLoading, error, candidat };
}




// Candidats list

export function useFetchCandidatAll() {
    const [candidatAll, setCandidatAll] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const candidatGetAll = getDataFromFile(localvalueStorage.CANDIDATS) || []


    useEffect(() => {
        setCandidatAll(candidatGetAll)
        async function fetchData() {
            setIsLoading(true);
            // console.log(idCandidat);
            await axios.get(`${baseurl.url}/api/v1/candidat/get_candidats`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            }).then((response) => {
                setCandidatAll(response.data.data);
                saveDataToFile(response.data.data, localvalueStorage.CANDIDATS)
                setError(null);
                // console.log(response.data.data)
            })
                .catch((error) => {
                    // console.log(error);
                    setError(error);
                });

            setIsLoading(false);
        }

        if (idCandidat) {
            fetchData();
        }
    }, [idCandidat]);

    return { isLoading, error, candidatAll };
}






// Reset password
export const UserSendCodeverfiy = async (
    email,
    phone,
    setSept,
    ) => {
    await axios
        .post(`${baseurl.url}/api/v1/users/send-code-verify`,
            {
                email,
                phone
            },
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            }
        )
        .then((response) => {
            toast.success(response?.data?.message || "Un code vous été envoyer sur votre email .", { position: "bottom-right" });
            setSept(2)

        })
        .catch((error) => {
            toast.error(error?.response?.data?.message || "Le code n'a pas été entrez email valide . ", { position: "bottom-right" })
        });
}




// Reset password
export const UserverfiyCode = async (
    email, phone,
    passwordverifield,
    setStep,
) => {
    await axios
        .post(`${baseurl.url}/api/v1/users/verfiy-code-reset`,
            {
                _id: email || phone,
                email,
                phone,
                passwordverifield,
            },
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            }
        )
        .then((response) => {
            toast.success(response?.data?.message || "Le Code est accepter avec succès .", { position: "bottom-right" });
            setStep(3)
        })
        .catch((error) => {
            toast.error(error?.response?.data?.message || "Le code n'a pas été accepter entrer un code valide ", { position: "bottom-right" })
        });
}





// Update statut user
export const UserUpdateStatut = async (
    idUser) => {
    await axios
        .patch(`${baseurl.url}/api/v1/users/statut_user/${idUser}`,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            }
        )
        .then((response) => {
            // dispatch({ type: FETCH_USER_SUCCESS, payload: response.data.data });
            toast.success(response.data.message, { position: "bottom-right" });
        })
        .catch((error) => {
            toast.error(`${error.response.data.message}`, { position: "bottom-right" })
            // dispatch({ type: FETCH_USER_FAILURE, payload: error.message });
        });
}




// Reset password
export const UserResetPasswordForget = async (
    email,
    password,
    setStep,
    showModal) => {
    await axios
        .post(`${baseurl.url}/api/v1/users/reset-password`,
            {
                email,
                password
            },
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            }
        )
        .then((response) => {
            toast.success(response?.data?.message || "Mot de passe mise  à jour avec succès", { position: "bottom-right" });
            setStep(1);
            showModal(true);
            window.location.href = `/`;
        })
        .catch((error) => {
            toast.error(error?.response?.data?.message || "Mot de passe non mis à jour", { position: "bottom-right" })
        });
}
